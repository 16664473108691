import React, { useContext, useEffect, useMemo, useState } from "react";
import { IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../context/AppContext";
import { List, ListItem, Button, Checkbox } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";

const UserList = ({ userList, setUserList, selected, setSelected }) => {
  const { getUsers, users, url } = useContext(AppContext);
  useEffect(() => {
    getUsers();
  }, []);
  //
  const [selectedUser, setSelectedUser] = useState([]);
  const handleSelected = (e, item) => {
    if (e.target.checked === true) {
      setSelectedUser([...selectedUser, item]);
    } else if (e.target.checked === false) {
      const filter = selectedUser.filter((item2) => {
        return item2._id !== item._id;
      });
      setSelectedUser(filter);
    }
  };
  //
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const shareLeads = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.post(
        `${url}/tcm/lead/share`,
        { leadIds: selected, userIds: selectedUser },
        {
          headers: {
            token: tcm_admin,
          },
        }
      );
      if (res.data.success === true) {
        setIsError(false);
        setMessage(res.data.message);
        setUserList(false);
        setSelected([]);
        setSelectedUser([]);
      } else if (res.data.success === false) {
        setIsError(true);
        setMessage(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  useMemo(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <Modal
        open={userList}
        onClose={() => setUserList(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 400,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <p>Select the users</p>
            <IconButton aria-label="close" onClick={() => setUserList(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          {/*  */}
          <>
            <List component="ul">
              {users.map((item, index) => {
                return (
                  <ListItem
                    className="border-bottom d-flex justify-content-between align-items-center"
                    key={index}
                  >
                    <p>{item.name}</p>
                    <Checkbox onClick={(e) => handleSelected(e, item)} />
                  </ListItem>
                );
              })}
            </List>
            <div className="text-end">
              <p className={`${!isError ? "text-success" : "text-danger"}`}>
                {message}
              </p>
              <Button variant="contained" onClick={shareLeads}>
                Share
              </Button>
            </div>
          </>
          {/*  */}
        </Box>
      </Modal>
    </>
  );
};

export default UserList;
