import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const ConfirmWithhold = ({
  withholdModal,
  setWithholdModal,
  confirmLead,
  withholdLead,
}) => {
  return (
    <>
      <Modal
        open={withholdModal}
        onClose={() => setWithholdModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <>
            <h2 className="mb-2">
              Withhold {confirmLead && confirmLead.lead.customerName}?
            </h2>
          </>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <Button
              color="danger"
              className="text-white"
              onClick={withholdLead}
              variant="contained"
            >
              Yes
            </Button>
            <Button
              onClick={() => setWithholdModal(false)}
              color="primary"
              variant="contained"
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmWithhold;
