import React, { useContext, useState, useRef, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonGroup,
  IconButton,
  Checkbox,
  TablePagination,
  TextField,
  Button,
} from "@mui/material";
//
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
//
import { AppContext } from "../context/AppContext";
import Cookies from "js-cookie";
import axios from "axios";
import ViewLead from "../components/ViewLead";
import AddLead from "../components/AddLead";
import EditLead from "../components/EditLead";
import DeleteLead from "../components/DeleteLead";
import UploadLead from "../components/UploadLead";
import SearchFilter from "../components/SearchFilter";
import UserList from "../components/UserList";
import ToggleNav from "../components/ToggleNav";
import DeleteMultipleLeads from "../components/DeleteMultipleLeads";
import NoDataFound from "../components/NoDataFound";

const Home = () => {
  const {
    url,
    getAdmin,
    adminLeads,
    adminLeads2,
    setAdminLeads,
    setAdminLeads2,
  } = useContext(AppContext);
  //
  const getAdminLeads = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.get(
        `${url}/tcm/lead/admin?page=${page + 1}&pageSize=${rowsPerPage}`,
        {
          headers: {
            token: tcm_admin,
          },
        }
      );
      if (res.data.success === true) {
        setAdminLeads(res.data.result);
        setAdminLeads2(res.data.result);
        setTotalCount(res.data.totalCount);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const [addModal, setAddModal] = useState(false);
  //
  const [viewModal, setViewModal] = useState(false);
  const [details, setDetails] = useState(null);
  const handleViewModal = (item) => {
    setViewModal(true);
    setDetails(item);
  };
  //
  const [editModal, setEditModal] = useState(false);
  const [edits, setEdits] = useState(null);
  const handleEdits = async (item) => {
    setEditModal(true);
    try {
      const tcm_admin = Cookies.get("tcm_admin");
      const res = await axios.get(`${url}/tcm/lead/${item._id}`, {
        headers: {
          token: tcm_admin,
        },
      });
      if (res.data.success === true) {
        setEdits(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditInputs = (e) => {
    const { name, value } = e.target;
    setEdits({
      ...edits,
      [name]: value,
    });
  };
  //
  const [deleleModal, setDeleleModal] = useState(false);
  const [confirmLead, setConfirmLead] = useState(null);
  const confirmDeleteModal = (item) => {
    setConfirmLead(item);
    setDeleleModal(true);
  };
  const deleteLead = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.delete(`${url}/tcm/lead/${confirmLead._id}`, {
        headers: {
          token: tcm_admin,
        },
      });
      if (res.data.success === true) {
        setDeleleModal(false);
        getAdmin();
        getAdminLeads();
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const [uploadModal, setUploadModal] = useState(false);
  //
  const [filterModal, setFilterModal] = useState(false);
  //
  const [selected, setSelected] = useState([]);
  const handleSelected = (e, item) => {
    if (e.target.checked === true) {
      setSelected([...selected, item._id]);
    } else if (e.target.checked === false) {
      const filter = selected.filter((item2) => {
        return item2 !== item._id;
      });
      setSelected(filter);
    }
  };
  const selectAllLeads = () => {
    const leadID = [];
    adminLeads2.forEach((item) => {
      leadID.push(item._id);
    });
    setSelected(leadID);
  };
  //
  const [userList, setUserList] = useState(false);
  const shareSingleLead = (item) => {
    setSelected([item._id]);
    setUserList(true);
  };
  //
  const [indexSorted, setIndexSorted] = useState(false);
  const indexArrow = useRef(null);
  const sortIndex = () => {
    setIndexSorted(!indexSorted);
    indexArrow.current.classList.toggle("rotate");
    setAdminLeads2(adminLeads2.reverse());
  };
  //
  const [salarySorted, setSalarySorted] = useState(false);
  const salaryArrow = useRef(null);
  const sortSalary = () => {
    if (salarySorted === true) {
      setSalarySorted(false);
      const sorted = adminLeads2.sort(
        (a, b) => b.customerSalary - a.customerSalary
      );
      setAdminLeads2(sorted);
      salaryArrow.current.classList.add("rotate");
    } else if (salarySorted === false) {
      setSalarySorted(true);
      const sorted = adminLeads2.sort(
        (a, b) => a.customerSalary - b.customerSalary
      );
      setAdminLeads2(sorted);
      salaryArrow.current.classList.remove("rotate");
    }
  };
  //
  const [scoreSorted, setScoreSorted] = useState(false);
  const scoreArrow = useRef(null);
  const sortScore = () => {
    if (scoreSorted === true) {
      setScoreSorted(false);
      const sorted = adminLeads2.sort((a, b) => b.creditScore - a.creditScore);
      setAdminLeads2(sorted);
      scoreArrow.current.classList.add("rotate");
    } else if (scoreSorted === false) {
      setScoreSorted(true);
      const sorted = adminLeads2.sort((a, b) => a.creditScore - b.creditScore);
      setAdminLeads2(sorted);
      scoreArrow.current.classList.remove("rotate");
    }
  };
  //
  const [searchInput, setSearchInput] = useState("");
  const [sliderValue1, setsliderValue1] = useState([0, 0]);
  const handleSliderChange1 = (e, newValue) => {
    setsliderValue1(newValue);
  };
  const applyFilter = () => {
    let updated = adminLeads;
    if (searchInput !== "") {
      updated = updated.filter((item) => {
        return (
          item.customerName.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.customerNumber
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          item.companyName.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    }
    if (sliderValue1[0] >= 300 && sliderValue1[1] <= 900) {
      updated = updated.filter((item) => {
        return (
          parseFloat(item.creditScore) >= sliderValue1[0] &&
          parseFloat(item.creditScore) <= sliderValue1[1]
        );
      });
    }
    //
    setAdminLeads2(updated);
    setFilterModal(false);
    setSelected([]);
  };
  const resetFilter = () => {
    setsliderValue1([0, 0]);
    setAdminLeads2(adminLeads);
    setFilterModal(false);
    setSearchInput("");
  };
  //
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  //
  const [jumpToPage, setJumpToPage] = useState(1);
  const handleJumpToPage = () => {
    const totalPages = Math.ceil(totalCount / rowsPerPage);
    const newPage = Math.min(Math.max(jumpToPage - 1, 0), totalPages - 1);
    setPage(newPage);
  };
  //
  useEffect(() => {
    getAdminLeads();
  }, [page, rowsPerPage]);
  //
  const [deleteManyModal, setDeleteManyModal] = useState(false);
  const deleteManyLeads = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.post(
        `${url}/tcm/lead/delete/many`,
        {
          leads: selected,
        },
        {
          headers: {
            token: tcm_admin,
          },
        }
      );
      if (res.data.success === true) {
        getAdmin();
        getAdminLeads();
        setDeleteManyModal(false);
        setSelected([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const [rowIndex, setRowIndex] = useState(null);
  const handleMouseEnter = (index) => {
    setRowIndex(index);
  };
  const handleMouseLeave = () => {
    setRowIndex(null);
  };
  return (
    <>
      <div className="container-fluid p-2">
        <ToggleNav
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          applyFilter={applyFilter}
          setAddModal={setAddModal}
          setUploadModal={setUploadModal}
          setFilterModal={setFilterModal}
          length={adminLeads.length}
        />
        {adminLeads2.length !== 0 ? (
          <>
            <TableContainer component={Paper} className="table-container">
              <Table>
                <TableHead>
                  <TableRow className="table-background">
                    <TableCell className="border" sx={{ minWidth: "75px" }}>
                      {selected.length === adminLeads2.length ? (
                        <IconButton
                          color="primary"
                          onClick={() => setSelected([])}
                        >
                          <IndeterminateCheckBoxIcon />
                        </IconButton>
                      ) : (
                        <Checkbox onChange={selectAllLeads} />
                      )}
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "100px" }}>
                      Sr No
                      <ArrowDownwardIcon
                        onClick={sortIndex}
                        className="indexArrow"
                        ref={indexArrow}
                      />
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "200px" }}>
                      Name
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "150px" }}>
                      Mobile number
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "200px" }}>
                      Company name
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "150px" }}>
                      Salary{" "}
                      <ArrowDownwardIcon
                        onClick={sortSalary}
                        className="salaryArrow"
                        ref={salaryArrow}
                      />
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "150px" }}>
                      Credit score{" "}
                      <ArrowDownwardIcon
                        onClick={sortScore}
                        className="scoreArrow"
                        ref={scoreArrow}
                      />
                    </TableCell>
                    <TableCell
                      className="border text-end"
                      sx={{ minWidth: "200px" }}
                    >
                      {selected.length === 0 ? (
                        ""
                      ) : (
                        <>
                          {selected.length === adminLeads2.length ? (
                            ""
                          ) : (
                            <IconButton
                              color="primary"
                              onClick={() => setSelected([])}
                            >
                              <IndeterminateCheckBoxIcon />
                            </IconButton>
                          )}

                          <IconButton
                            color="primary"
                            onClick={() => setUserList(true)}
                          >
                            <ShareIcon />
                          </IconButton>
                          <IconButton
                            color="danger"
                            onClick={() => setDeleteManyModal(true)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminLeads2.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        className={
                          index === rowIndex || selected.includes(item._id)
                            ? "table-background"
                            : ""
                        }
                      >
                        <TableCell className="border">
                          <Checkbox
                            onChange={(e) => handleSelected(e, item)}
                            checked={selected.includes(item._id)}
                          />
                        </TableCell>
                        <TableCell className="border">{index + 1}</TableCell>
                        <TableCell className="border">
                          <span className="text-clamp">
                            {item.customerName}
                          </span>
                        </TableCell>
                        <TableCell className="border">
                          {item.customerNumber}
                        </TableCell>
                        <TableCell className="border">
                          <span className="text-clamp">{item.companyName}</span>
                        </TableCell>
                        <TableCell className="border">
                          {item.customerSalary}
                        </TableCell>
                        <TableCell className="border">
                          {item.creditScore}
                        </TableCell>
                        <TableCell className="border">
                          <ButtonGroup>
                            <IconButton
                              color="primary"
                              onClick={() => handleViewModal(item)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {selected.length === 0 ? (
                              <IconButton
                                color="primary"
                                onClick={() => handleEdits(item)}
                              >
                                <CreateIcon />
                              </IconButton>
                            ) : (
                              ""
                            )}
                            {selected.length === 0 ? (
                              <IconButton
                                color="primary"
                                onClick={() => shareSingleLead(item)}
                              >
                                <ShareIcon />
                              </IconButton>
                            ) : (
                              ""
                            )}
                            {selected.length === 0 ? (
                              <IconButton
                                color="danger"
                                onClick={() => confirmDeleteModal(item)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="d-flex align-items-center justify-content-between gap-2">
              <TablePagination
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
              <div className="d-flex align-items-end justify-content-end gap-2">
                <TextField
                  label="Jump to Page"
                  type="number"
                  value={jumpToPage}
                  onChange={(e) => setJumpToPage(e.target.value)}
                  variant="standard"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleJumpToPage}
                >
                  Jump
                </Button>
              </div>
            </div>
          </>
        ) : (
          <NoDataFound />
        )}
        {/* View */}
        <ViewLead
          viewModal={viewModal}
          setViewModal={setViewModal}
          details={details}
        />

        {/* Add */}
        <AddLead
          addModal={addModal}
          setAddModal={setAddModal}
          getAdminLeads={getAdminLeads}
        />

        {/* Edit */}
        <EditLead
          editModal={editModal}
          setEditModal={setEditModal}
          edits={edits}
          setEdits={setEdits}
          handleEditInputs={handleEditInputs}
          getAdminLeads={getAdminLeads}
        />

        {/* Delete */}
        <DeleteLead
          deleleModal={deleleModal}
          setDeleleModal={setDeleleModal}
          confirmLead={confirmLead}
          deleteLead={deleteLead}
        />

        {/* Upload */}
        <UploadLead
          uploadModal={uploadModal}
          setUploadModal={setUploadModal}
          getAdminLeads={getAdminLeads}
        />

        {/* Search Filter */}
        <SearchFilter
          filterModal={filterModal}
          setFilterModal={setFilterModal}
          setSelected={setSelected}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
          title1="Credit score"
          sliderValue1={sliderValue1}
          handleSliderChange1={handleSliderChange1}
          minVal1={300}
          maxVal1={900}
        />

        {/* User List */}
        <UserList
          userList={userList}
          setUserList={setUserList}
          selected={selected}
          setSelected={setSelected}
        />

        {/* Delete Many */}
        <DeleteMultipleLeads
          deleteManyModal={deleteManyModal}
          setDeleteManyModal={setDeleteManyModal}
          deleteManyLeads={deleteManyLeads}
          length={selected.length}
        />
      </div>
    </>
  );
};

export default Home;
