import React, { useContext } from "react";
import { IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import moment from "moment/moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import UpdateIcon from "@mui/icons-material/Update";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { AppContext } from "../context/AppContext";
import axios from "axios";
import Cookies from "js-cookie";

const ViewLead = ({ viewModal, details, setViewModal }) => {
  const { url } = useContext(AppContext);
  const downloadPDF = async (details) => {
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.get(
        `${url}/tcm/lead/download/${details._id}`,
        { responseType: "blob" },
        {
          headers: {
            token: tcm_admin,
          },
        }
      );
      //
      const blob = new Blob([res.data], { type: "application/pdf" });
      const linked = window.URL.createObjectURL(blob);
      //
      const link = document.createElement("a");
      link.href = linked;
      link.download = `${details.customerName
        .split(" ")
        .join("_")}_credit_score_file.pdf`;
      document.body.appendChild(link);
      link.click();
      //
      document.body.removeChild(link);
      window.URL.revokeObjectURL(linked);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        open={viewModal}
        onClose={() => setViewModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "500px",
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          {details !== null ? (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <p>
                  <PersonIcon />
                  <span className="fw-bold">{details.customerName}</span>
                </p>
                <IconButton
                  aria-label="close"
                  onClick={() => setViewModal(false)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <>
                <p className="mb-2">
                  <PhoneIcon />
                  Customer number :{" "}
                  <span className="fw-bold">{details.customerNumber}</span>
                </p>
                {/*  */}
                {details.customerSalary ? (
                  <p className="mb-2">
                    <AttachMoneyIcon />
                    Customer salary :{" "}
                    <span className="fw-bold">{details.customerSalary}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.creditScore ? (
                  <p className="mb-2">
                    <CreditScoreIcon />
                    Credit score :{" "}
                    <span className="fw-bold">{details.creditScore}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.companyName ? (
                  <p className="mb-2">
                    <BusinessCenterIcon />
                    Company name :{" "}
                    <span className="fw-bold">{details.companyName}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.missedEMI ? (
                  <p className="mb-2">
                    <MoneyOffIcon />
                    Missed EMI :{" "}
                    <span className="fw-bold">{details.missedEMI}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.totalCreditors ? (
                  <p className="mb-2">
                    <AccountBalanceIcon />
                    Total Creditors :{" "}
                    <span className="fw-bold">{details.totalCreditors}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.leadStatus ? (
                  <p className="mb-2">
                    <AutorenewIcon />
                    Lead status :{" "}
                    <span className="fw-bold">{details.leadStatus}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.comment ? (
                  <p className="mb-2">
                    <QuestionAnswerIcon />
                    Comment : <span className="fw-bold">{details.comment}</span>
                  </p>
                ) : (
                  ""
                )}
                {details.admin ? (
                  <p className="mb-2">
                    <AdminPanelSettingsIcon />
                    Uploaded by :{" "}
                    <span className="fw-bold">{details.admin.name}</span>
                  </p>
                ) : (
                  ""
                )}
                <p className="mb-2">
                  <AccessTimeIcon />
                  Lead created at :{" "}
                  <span className="fw-bold">
                    {moment(details.createdAt).format(
                      "Do MMMM YYYY, h:mm:ss a"
                    )}
                  </span>
                </p>
                <p className="mb-2">
                  <UpdateIcon />
                  Lead updated at :{" "}
                  <span className="fw-bold">
                    {moment(details.updatedAt).format(
                      "Do MMMM YYYY, h:mm:ss a"
                    )}
                  </span>
                </p>
                {details.creditScoreFile && details.creditScoreFile !== "" ? (
                  <IconButton
                    sx={{ color: "black" }}
                    onClick={() => downloadPDF(details)}
                  >
                    <FileOpenIcon />
                  </IconButton>
                ) : (
                  ""
                )}
              </>
            </>
          ) : (
            ""
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ViewLead;
