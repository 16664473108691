import React, { useContext, useMemo, useState } from "react";
import { Button, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../context/AppContext";

const ConfirmDeleteAccount = ({
  deleteAccountModal,
  setDeleteAccountModal,
}) => {
  const { url, admin } = useContext(AppContext);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  //
  const deleteAccount = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    if (password === "") {
      setMessage("Enter password to continue");
    } else {
      try {
        const res = await axios.post(
          `${url}/admin/${admin._id}`,
          {
            password,
          },
          {
            headers: {
              token: tcm_admin,
            },
          }
        );
        if (res.data.success === true) {
          Cookies.remove("tcm_admin");
          navigate("/login", { replace: true });
        } else if (res.data.success === false) {
          setMessage(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useMemo(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
  }, [message]);
  return (
    <>
      <Modal
        open={deleteAccountModal}
        onClose={() => setDeleteAccountModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <>
            <p className="mb-2">Enter Password to delete account</p>
            <small className="text-danger">
              Note: This process is irreverseible. Your account and all the
              leads associated with it will be deleted.
            </small>
          </>

          <TextField
            label="Enter password"
            variant="standard"
            fullWidth
            type="text"
            margin="normal"
            name="customerName"
            autoComplete="off"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="mt-2 text-end">
            <p className="text-danger">{message}</p>
          </div>
          <div className="d-flex align-items-center justify-content-end gap-2 mt-2">
            <Button
              color="primary"
              variant="contained"
              onClick={() => setDeleteAccountModal(false)}
            >
              Cancel
            </Button>
            <Button
              color="danger"
              className="text-white"
              onClick={deleteAccount}
              variant="contained"
            >
              Delete
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmDeleteAccount;
