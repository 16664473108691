import { Button, ButtonGroup, IconButton, TextField } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TuneIcon from "@mui/icons-material/Tune";

const ToggleNav = ({
  searchInput,
  setSearchInput,
  applyFilter,
  setAddModal,
  setUploadModal,
  setFilterModal,
  length,
}) => {
  const location = useLocation();
  return (
    <>
      <div className="top-grid mb-2">
        <div>
          <div className="d-flex align-items-center justify-content start gap-2">
            <Link to="/" className="text-white">
              <Button
                variant={location.pathname === "/" ? "contained" : "outlined"}
              >
                My Leads
              </Button>
            </Link>
            <Link to="/allleads" className="primary-text">
              <Button
                variant={
                  location.pathname === "/allleads" ? "contained" : "outlined"
                }
              >
                All Leads
              </Button>
            </Link>
          </div>
        </div>
        <div className="top-inner-grid">
          <div>
            <TextField
              label="Search"
              variant="standard"
              fullWidth
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyUp={applyFilter}
              autoComplete="off"
              disabled={length === 0 ? true : false}
            />
          </div>
          <div className="top-icon-grid">
            <IconButton
              color="primary"
              aria-label="upload file"
              component="span"
              onClick={() => setAddModal(true)}
            >
              <AddIcon />
            </IconButton>

            <IconButton
              color="primary"
              aria-label="upload file"
              component="span"
              onClick={() => setUploadModal(true)}
            >
              <CloudUploadIcon />
            </IconButton>

            <IconButton
              color="primary"
              aria-label="upload file"
              component="span"
              onClick={() => setFilterModal(true)}
              disabled={length === 0 ? true : false}
            >
              <TuneIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToggleNav;
