import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./context/Theme";
import AddAccount from "./pages/AddAccount";
import Login from "./pages/Login";
import User from "./pages/User";
import Auth from "./utility/Auth";
import { AppContextProvider } from "./context/AppContext";
import Home from "./pages/Home";
import AllLeads from "./pages/AllLeads";
import ViewUser from "./pages/ViewUser";
import SharedByAdmin from "./pages/SharedByAdmin";
import SharedByUser from "./pages/SharedByUser";
import Profile from "./pages/Profile";
import Request from "./pages/Request";
import SecretKey from "./pages/SecretKey";

const App = () => {
  return (
    <AppContextProvider>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Auth Component={Home} />} />
          <Route path="/allleads" element={<Auth Component={AllLeads} />} />
          <Route path="/users" element={<Auth Component={User} />}>
            <Route path=":id" element={<ViewUser />}>
              <Route path="sharedbyadmin" element={<SharedByAdmin />} />
              <Route path="sharedbyuser" element={<SharedByUser />} />
            </Route>
          </Route>
          <Route path="/profile" element={<Auth Component={Profile} />} />
          <Route path="/request" element={<Auth Component={Request} />} />
          <Route path="/secretkey" element={<Auth Component={SecretKey} />} />
          {/*  */}
          <Route path="/login" element={<Login />} />
          <Route path="/addaccount" element={<Auth Component={AddAccount} />} />
        </Routes>
      </ThemeProvider>
    </AppContextProvider>
  );
};

export default App;
