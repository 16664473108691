import React, { useContext, useEffect, useState, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonGroup,
  IconButton,
  TextField,
  Checkbox,
  TablePagination,
  Button,
} from "@mui/material";
//
import VisibilityIcon from "@mui/icons-material/Visibility";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ViewLead from "../components/ViewLead";
import TuneIcon from "@mui/icons-material/Tune";
import SearchFilter from "../components/SearchFilter";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ConfirmWithhold from "../components/ConfirmWithhold";
import ConfirmMultipleWithhold from "../components/ConfirmMultipleWithhold";
import NoDataFound from "../components/NoDataFound";

const SharedByAdmin = () => {
  const { url } = useContext(AppContext);
  const location = useLocation();
  const userID = location.pathname.split("/")[2];
  const [leads, setLeads] = useState([]);
  const [leads2, setLeads2] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const getSharedByAdmin = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.get(`${url}/tcm/lead/shared/byadmin/${userID}`, {
        headers: {
          token: tcm_admin,
        },
      });
      if (res.data.success === true) {
        setLeads(res.data.result);
        setLeads2(res.data.result);
        setTotalCount(res.data.totalCount);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSharedByAdmin();
  }, [userID]);
  //
  const [selected, setSelected] = useState([]);
  const handleSelected = (e, item) => {
    if (e.target.checked === true) {
      setSelected([...selected, item.lead._id]);
    } else if (e.target.checked === false) {
      const filter = selected.filter((item2) => {
        return item2 !== item.lead._id;
      });
      setSelected(filter);
    }
  };
  const selectAllLeads = () => {
    const leadID = [];
    leads2.forEach((item) => {
      leadID.push(item.lead._id);
    });
    setSelected(leadID);
  };
  //
  const [indexSorted, setIndexSorted] = useState(false);
  const indexArrow = useRef(null);
  const sortIndex = () => {
    setIndexSorted(!indexSorted);
    indexArrow.current.classList.toggle("rotate");
    setLeads2(leads2.reverse());
  };
  //
  const [salarySorted, setSalarySorted] = useState(false);
  const salaryArrow = useRef(null);
  const sortSalary = () => {
    if (salarySorted === true) {
      setSalarySorted(false);
      const sorted = leads2.sort((a, b) => b.customerSalary - a.customerSalary);
      setLeads2(sorted);
      salaryArrow.current.classList.add("rotate");
    } else if (salarySorted === false) {
      setSalarySorted(true);
      const sorted = leads2.sort((a, b) => a.customerSalary - b.customerSalary);
      setLeads2(sorted);
      salaryArrow.current.classList.remove("rotate");
    }
  };
  //
  const [scoreSorted, setScoreSorted] = useState(false);
  const scoreArrow = useRef(null);
  const sortScore = () => {
    if (scoreSorted === true) {
      setScoreSorted(false);
      const sorted = leads2.sort((a, b) => b.creditScore - a.creditScore);
      setLeads2(sorted);
      scoreArrow.current.classList.add("rotate");
    } else if (scoreSorted === false) {
      setScoreSorted(true);
      const sorted = leads2.sort((a, b) => a.creditScore - b.creditScore);
      setLeads2(sorted);
      scoreArrow.current.classList.remove("rotate");
    }
  };
  //
  const [viewModal, setViewModal] = useState(false);
  const [details, setDetails] = useState(null);
  const handleViewModal = (item) => {
    setViewModal(true);
    setDetails({
      ...item.lead,
      leadStatus: item.leadStatus,
      comment: item.comment,
    });
  };
  //
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  const [jumpToPage, setJumpToPage] = useState(1);
  const handleJumpToPage = () => {
    const totalPages = Math.ceil(totalCount / rowsPerPage);
    const newPage = Math.min(Math.max(jumpToPage - 1, 0), totalPages - 1);
    setPage(newPage);
  };
  //
  const [searchInput, setSearchInput] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [sliderValue1, setsliderValue1] = useState([0, 0]);
  const handleSliderChange1 = (e, newValue) => {
    setsliderValue1(newValue);
  };
  const [status, setStatus] = useState("");
  const applyFilter = () => {
    let updated = leads;
    if (searchInput !== "") {
      updated = updated.filter((item) => {
        return (
          item.lead.customerName
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          item.lead.customerNumber
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          item.companyName.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    }
    if (sliderValue1[0] >= 300 && sliderValue1[1] <= 900) {
      updated = updated.filter((item) => {
        return (
          parseFloat(item.lead.creditScore) >= sliderValue1[0] &&
          parseFloat(item.lead.creditScore) <= sliderValue1[1]
        );
      });
    }
    if (status !== "") {
      updated = updated.filter((item) => {
        return (
          item.leadStatus &&
          item.leadStatus.toLowerCase() === status.toLowerCase()
        );
      });
    }
    //
    setLeads2(updated);
    setFilterModal(false);
    setSelected([]);
  };
  const resetFilter = () => {
    setsliderValue1([0, 0]);
    setLeads2(leads);
    setFilterModal(false);
    setSearchInput("");
    setStatus("");
  };
  //
  const [rowIndex, setRowIndex] = useState(null);
  const handleMouseEnter = (index) => {
    setRowIndex(index);
  };
  const handleMouseLeave = () => {
    setRowIndex(null);
  };
  //
  const [withholdModal, setWithholdModal] = useState(false);
  const [confirmLead, setConfirmLead] = useState(false);
  const confirmWithhold = (item) => {
    setWithholdModal(true);
    setConfirmLead(item);
  };
  const withholdLead = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.post(
        `${url}/tcm/lead/withhold`,
        {
          lead: confirmLead.lead._id,
          user: userID,
        },
        {
          headers: {
            token: tcm_admin,
          },
        }
      );
      if (res.data.success === true) {
        getSharedByAdmin();
        setWithholdModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const [withholdManyModal, setWithholdManyModal] = useState(false);
  const withholdManyLeads = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.post(
        `${url}/tcm/lead/withhold/many`,
        {
          leads: selected,
          user: userID,
        },
        {
          headers: {
            token: tcm_admin,
          },
        }
      );
      if (res.data.success === true) {
        setSelected([]);
        getSharedByAdmin();
        setWithholdManyModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {leads.length !== 0 ? (
        <>
          <div className="shared-grid mb-2">
            <div>
              <TextField
                label="Search"
                variant="standard"
                fullWidth
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyUp={applyFilter}
                autoComplete="off"
              />
            </div>

            <div className="text-end">
              <IconButton
                color="primary"
                aria-label="upload file"
                component="span"
                onClick={() => setFilterModal(true)}
              >
                <TuneIcon />
              </IconButton>
            </div>
          </div>
          {/*  */}
          <TableContainer component={Paper} className="table-container">
            <Table>
              <TableHead>
                <TableRow className="table-background">
                  <TableCell className="border" sx={{ minWidth: "75px" }}>
                    {selected.length !== 0 &&
                    selected.length === leads2.length ? (
                      <IconButton
                        color="primary"
                        onClick={() => setSelected([])}
                      >
                        <IndeterminateCheckBoxIcon />
                      </IconButton>
                    ) : (
                      <Checkbox onChange={selectAllLeads} />
                    )}
                  </TableCell>
                  <TableCell className="border" sx={{ minWidth: "100px" }}>
                    Sr No
                    <ArrowDownwardIcon
                      onClick={sortIndex}
                      className="indexArrow"
                      ref={indexArrow}
                    />
                  </TableCell>
                  <TableCell className="border" sx={{ minWidth: "200px" }}>
                    Name
                  </TableCell>
                  <TableCell className="border" sx={{ minWidth: "150px" }}>
                    Mobile Number
                  </TableCell>
                  <TableCell className="border" sx={{ minWidth: "200px" }}>
                    Company name
                  </TableCell>
                  <TableCell className="border" sx={{ minWidth: "150px" }}>
                    Salary{" "}
                    <ArrowDownwardIcon
                      onClick={sortSalary}
                      className="salaryArrow"
                      ref={salaryArrow}
                    />
                  </TableCell>
                  <TableCell className="border" sx={{ minWidth: "150px" }}>
                    Credit score{" "}
                    <ArrowDownwardIcon
                      onClick={sortScore}
                      className="scoreArrow"
                      ref={scoreArrow}
                    />
                  </TableCell>
                  <TableCell className="border" sx={{ minWidth: "150px" }}>
                    Lead status
                  </TableCell>
                  <TableCell className="border" sx={{ minWidth: "200px" }}>
                    Comment
                  </TableCell>
                  <TableCell
                    className="border text-end"
                    sx={{ minWidth: "100px" }}
                  >
                    {selected.length === 0 ? (
                      ""
                    ) : (
                      <>
                        {selected.length === leads2.length ? (
                          ""
                        ) : (
                          <IconButton
                            color="primary"
                            onClick={() => setSelected([])}
                          >
                            <IndeterminateCheckBoxIcon />
                          </IconButton>
                        )}
                        <IconButton
                          color="danger"
                          onClick={() => setWithholdManyModal(true)}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leads2.map((item, index) => {
                  return (
                    <TableRow
                      key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      className={
                        index === rowIndex || selected.includes(item.lead._id)
                          ? "table-background"
                          : ""
                      }
                    >
                      <TableCell className="border">
                        <Checkbox
                          onChange={(e) => handleSelected(e, item)}
                          checked={selected.includes(item.lead._id)}
                        />
                      </TableCell>
                      <TableCell className="border">{index + 1}</TableCell>
                      <TableCell className="border">
                        <span className="text-clamp">
                          {item.lead.customerName}
                        </span>
                      </TableCell>
                      <TableCell className="border">
                        {item.lead.customerNumber}
                      </TableCell>
                      <TableCell className="border">
                        <span className="text-clamp">
                          {item.lead.companyName}
                        </span>
                      </TableCell>
                      <TableCell className="border">
                        {item.lead.customerSalary}
                      </TableCell>
                      <TableCell className="border">
                        {item.lead.creditScore}
                      </TableCell>
                      <TableCell className="border">
                        {item.leadStatus}
                      </TableCell>
                      <TableCell className="border">
                        <span className="text-clamp">{item.comment}</span>
                      </TableCell>
                      <TableCell className="border">
                        <ButtonGroup>
                          <IconButton
                            color="primary"
                            onClick={() => handleViewModal(item)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                          {selected.length === 0 ? (
                            <IconButton
                              color="danger"
                              onClick={() => confirmWithhold(item)}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[25, 50, 75, 100]}
          />
          <div className="d-flex align-items-end justify-content-end gap-2">
            <TextField
              label="Jump to Page"
              type="number"
              value={jumpToPage}
              onChange={(e) => setJumpToPage(e.target.value)}
              variant="standard"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleJumpToPage}
            >
              Jump
            </Button>
          </div>

          {/* View */}
          <ViewLead
            viewModal={viewModal}
            setViewModal={setViewModal}
            details={details}
          />

          <SearchFilter
            filterModal={filterModal}
            setFilterModal={setFilterModal}
            setSelected={setSelected}
            status={status}
            setStatus={setStatus}
            applyFilter={applyFilter}
            resetFilter={resetFilter}
            title1="Credit score"
            sliderValue1={sliderValue1}
            handleSliderChange1={handleSliderChange1}
            minVal1={300}
            maxVal1={900}
          />

          <ConfirmWithhold
            withholdModal={withholdModal}
            setWithholdModal={setWithholdModal}
            confirmLead={confirmLead}
            withholdLead={withholdLead}
          />

          {/*  */}
          <ConfirmMultipleWithhold
            withholdManyModal={withholdManyModal}
            setWithholdManyModal={setWithholdManyModal}
            withholdManyLeads={withholdManyLeads}
            length={selected.length}
          />
        </>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default SharedByAdmin;
