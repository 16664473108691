import React from "react";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";

const DeleteSecretKey = ({
  deleteSecretKeymodal,
  setDeleteSecretKeymodal,
  confirmSecretKey,
  deleteSecretKey,
}) => {
  return (
    <>
      <Modal
        open={deleteSecretKeymodal}
        onClose={() => setDeleteSecretKeymodal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <>
            <p className="mb-2">
              Delete {confirmSecretKey && confirmSecretKey.secret_key}?
            </p>
          </>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <Button
              color="danger"
              onClick={deleteSecretKey}
              variant="contained"
              className="text-white"
            >
              Yes
            </Button>
            <Button
              onClick={() => setDeleteSecretKeymodal(false)}
              color="primary"
              variant="contained"
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteSecretKey;
