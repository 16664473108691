import React, { useContext } from "react";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import axios from "axios";
import { AppContext } from "../context/AppContext";

const ConfirmRestrictPermit = ({
  restrictmodal,
  setRestrictModal,
  confirmRestrict,
  getUser,
}) => {
  const { url } = useContext(AppContext);
  const restrictUser = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.put(
        `${url}/user/restrictpermit/${confirmRestrict._id}`,
        {
          active: false,
        },
        {
          headers: {
            token: tcm_admin,
          },
        }
      );
      if (res.data.success === true) {
        getUser();
        setRestrictModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        open={restrictmodal}
        onClose={() => setRestrictModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <>
            <p className="mb-2">
              {confirmRestrict.active === true ? "Restrict" : "Permit"}{" "}
              {confirmRestrict !== null && confirmRestrict.name}?
            </p>
          </>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <Button
              className="text-white"
              variant="contained"
              color={confirmRestrict.active === true ? "danger" : "primary"}
              onClick={restrictUser}
            >
              Yes
            </Button>
            <Button
              color={confirmRestrict.active === true ? "primary" : "danger"}
              className="text-white"
              onClick={() => setRestrictModal(false)}
              variant="contained"
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmRestrictPermit;
