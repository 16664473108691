import React, { useContext } from "react";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import { AppContext } from "../context/AppContext";
import axios from "axios";

const ConfirmAccept = ({
  acceptModal,
  setAcceptModal,
  confirmAccept,
  getRequest,
}) => {
  const { url, getRequestedCount } = useContext(AppContext);
  const acceptRequest = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.put(
        `${url}/user/accept/${confirmAccept._id}`,
        {
          user: confirmAccept.name,
        },
        {
          headers: {
            token: tcm_admin,
          },
        }
      );
      if (res.data.success === true) {
        getRequest();
        setAcceptModal(false);
        getRequestedCount();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        open={acceptModal}
        onClose={() => setAcceptModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <>
            <h2 className="mb-2">
              Accept {confirmAccept !== null && confirmAccept.name}?
            </h2>
          </>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <Button
              className="text-white"
              variant="contained"
              onClick={acceptRequest}
            >
              Yes
            </Button>
            <Button
              color="danger"
              className="text-white"
              onClick={() => setAcceptModal(false)}
              variant="contained"
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmAccept;
