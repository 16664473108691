import React, { useContext, useEffect, useState } from "react";
import { Button, IconButton, Modal, TextField, Switch } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import axios from "axios";
import { AppContext } from "../context/AppContext";

const AddSecretKey = ({
  addSecretkeyModal,
  setAddSecretKeyModal,
  getSecretKeys,
}) => {
  const { url } = useContext(AppContext);
  //
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  //
  const [secret_key, setSecretKey] = useState("");
  //
  const [checkedButton, setCheckedButton] = useState(false);
  const handleChange = () => {
    setCheckedButton(!checkedButton);
  };
  //
  const addSecretKey = async (e) => {
    e.preventDefault();
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.post(
        `${url}/secretkey`,
        {
          secret_key,
          active: checkedButton,
        },
        {
          headers: {
            token: tcm_admin,
          },
        }
      );
      //
      if (res.data.success === true) {
        getSecretKeys();
        setAddSecretKeyModal(false);
        setIsError(false);
        setMessage(res.data.message);
        setSecretKey("");
        setCheckedButton(false);
      } else if (res.data.success === false) {
        setIsError(true);
        setMessage(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <Modal
        open={addSecretkeyModal}
        onClose={() => setAddSecretKeyModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 400,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h2>Add a secret key</h2>
            <IconButton
              aria-label="close"
              onClick={() => setAddSecretKeyModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <>
            <form>
              <TextField
                label="Secret key"
                variant="standard"
                fullWidth
                type="text"
                margin="normal"
                name="customerName"
                autoComplete="off"
                value={secret_key}
                onChange={(e) => setSecretKey(e.target.value)}
              />

              <div className="d-flex align-items-center justify-content-start gap-2 my-4">
                <Switch
                  active={checkedButton}
                  onChange={handleChange}
                  color="primary"
                  checked={checkedButton === true ? true : false}
                />
                <p>{checkedButton === true ? "Active" : "Inactive"}</p>
              </div>

              <div className="text-end">
                <p className={isError ? "text-danger" : "text-success"}>
                  {message}
                </p>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={addSecretKey}
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default AddSecretKey;
