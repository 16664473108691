import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Cookies from "js-cookie";
import { Button } from "@mui/material";
import moment from "moment";
import ConfirmRestrict from "../components/ConfirmRestrictPermit";

const ViewUser = () => {
  const { url } = useContext(AppContext);
  const { id } = useParams();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const getUser = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.get(`${url}/user/${id}`, {
        headers: {
          token: tcm_admin,
        },
      });
      if (res.data.success === true) {
        setUser(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUser();
  }, [id]);
  //
  const [restrictmodal, setRestrictModal] = useState(false);
  return (
    <>
      {user !== null ? (
        <>
          <p>
            Name: <span className="fw-bold">{user.name}</span>
          </p>
          <p>
            Username: <span className="fw-bold">{user.username}</span>
          </p>
          <p>
            Account status:{" "}
            <span
              className={`fw-bold ${
                user.active === true ? "text-success" : "text-danger"
              }`}
            >
              {user.active === true ? "Active" : "Inactive"}
            </span>
          </p>
          <p>
            Account created at:{" "}
            <span className="fw-bold">
              {moment(user.createdAt).format("Do MMMM YYYY")}
            </span>
          </p>

          {user.active === true ? (
            <Button
              variant="contained"
              color="danger"
              className="text-white mt-2"
              onClick={() => setRestrictModal(true)}
            >
              Restrict
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className="text-white mt-2"
              onClick={() => setRestrictModal(true)}
            >
              permit
            </Button>
          )}

          <hr />
          <div className="toggle-buttons my-2">
            <Link to={`sharedbyadmin`}>
              <Button
                variant={
                  location.pathname.split("/")[3] === "sharedbyadmin"
                    ? "contained"
                    : "outlined"
                }
              >
                Shared By TCM
              </Button>
            </Link>{" "}
            <Link to={`sharedbyuser`}>
              <Button
                variant={
                  location.pathname.split("/")[3] === "sharedbyuser"
                    ? "contained"
                    : "outlined"
                }
              >
                Shared By User
              </Button>
            </Link>
          </div>
          <ConfirmRestrict
            restrictmodal={restrictmodal}
            setRestrictModal={setRestrictModal}
            confirmRestrict={user}
            getUser={getUser}
          />
        </>
      ) : (
        ""
      )}

      <Outlet />
    </>
  );
};

export default ViewUser;
