import React, { useContext, useState } from "react";
import { Button, IconButton, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import { useLocation } from "react-router-dom";
import ViewFile from "./ViewFile";

const EditLead = ({
  editModal,
  setEditModal,
  edits,
  handleEditInputs,
  setEdits,
  getAdminLeads,
  getAllLeads,
}) => {
  const { url, getAdmin } = useContext(AppContext);
  const [creditScoreFile, setCreditScoreFile] = useState(null);
  const location = useLocation();
  const editLead = async (e) => {
    e.preventDefault();
    const tcm_admin = Cookies.get("tcm_admin");
    const formdata = new FormData();
    formdata.append("customerName", edits.customerName);
    formdata.append("customerNumber", edits.customerNumber);
    formdata.append("customerSalary", edits.customerSalary);
    formdata.append("creditScore", edits.creditScore);
    formdata.append("companyName", edits.companyName);
    if (creditScoreFile !== null) {
      formdata.append("creditScoreFile", creditScoreFile);
    }
    try {
      const res = await axios.put(
        `${url}/tcm/lead/admin/${edits._id}`,
        formdata,
        {
          headers: {
            token: tcm_admin,
          },
        }
      );
      if (res.data.success === true) {
        location.pathname === "/" ? getAdminLeads() : getAllLeads();
        getAdmin();
        setEdits(null);
        setEditModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const [fileModal, setFileModal] = useState(false);
  const [filename, setFilename] = useState("");
  const viewFile = () => {
    setFileModal(true);
    setFilename(edits.creditScoreFile);
  };
  //
  return (
    <>
      <Modal
        open={editModal}
        onClose={() => setEditModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 400,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <p>Edit a lead</p>
            <IconButton aria-label="close" onClick={() => setEditModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <>
            {edits !== null ? (
              <>
                <form>
                  <TextField
                    label="Customer Name"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    type="text"
                    name="customerName"
                    value={edits.customerName}
                    onChange={handleEditInputs}
                  />
                  <TextField
                    label="Customer Number"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    type="number"
                    name="customerNumber"
                    value={edits.customerNumber}
                    onChange={handleEditInputs}
                  />
                  <TextField
                    label="Company Name"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    type="text"
                    name="companyName"
                    value={edits.companyName}
                    onChange={handleEditInputs}
                  />
                  <TextField
                    label="Customer Salary"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    type="number"
                    name="customerSalary"
                    value={edits.customerSalary}
                    onChange={handleEditInputs}
                  />
                  {edits.creditScoreFile ? (
                    <div className="d-flex align-items-end justify-content-start gap-2">
                      <p>Credit score file:</p>
                      <div className="creditScoreFile">
                        <img
                          src={`${url}/${edits.creditScoreFile}`}
                          alt="creditScoreFile"
                          onClick={viewFile}
                        />
                      </div>
                    </div>
                  ) : (
                    <TextField
                      label="Credit Score"
                      variant="standard"
                      fullWidth
                      margin="normal"
                      type="number"
                      name="creditScore"
                      value={edits.creditScore}
                      onChange={handleEditInputs}
                    />
                  )}
                  <TextField
                    label="Credit Score"
                    variant="standard"
                    fullWidth
                    margin="normal"
                    type="file"
                    name="creditScore"
                  />
                  <div className="text-end mt-4">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={editLead}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </>
            ) : (
              ""
            )}
          </>
        </Box>
      </Modal>

      {/*  */}
      <ViewFile
        fileModal={fileModal}
        setFileModal={setFileModal}
        filename={filename}
      />
    </>
  );
};

export default EditLead;
