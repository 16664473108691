import React from "react";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";

const DeleteMultipleLeads = ({
  deleteManyModal,
  setDeleteManyModal,
  deleteManyLeads,
  length,
}) => {
  return (
    <>
      <Modal
        open={deleteManyModal}
        onClose={() => setDeleteManyModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <p className="mb-2">Delete {length} leads?</p>
          <div className="d-flex align-items-center justify-content-center gap-2 mt-2">
            <Button
              onClick={deleteManyLeads}
              color="danger"
              className="text-white"
              variant="contained"
            >
              Yes
            </Button>
            <Button
              onClick={() => setDeleteManyModal(false)}
              color="primary"
              variant="contained"
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteMultipleLeads;
