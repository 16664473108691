import React, { useContext, useMemo, useState } from "react";
import { Card, CardContent, TextField, Button } from "@mui/material";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import Cookies from "js-cookie";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const AddAccount = () => {
  const { url } = useContext(AppContext);
  const [inputs, setInputs] = useState({
    name: "",
    username: "",
    password: "",
    secretkey: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  //
  const [passwordShow, setPasswordShow] = useState(false);
  const [secretKeyShow, setSecretKeyShow] = useState(false);
  const handlePasswordShow = (e) => {
    setPasswordShow(!passwordShow);
  };
  const handleSecretKeyShow = (e) => {
    setSecretKeyShow(!secretKeyShow);
  };
  //
  const [isErr, setIsErr] = useState(false);
  const [msg, setMsg] = useState("");
  const handleSignup = async (e) => {
    e.preventDefault();
    const { name, username, password, secretkey } = inputs;
    if (!name) {
      setIsErr(true);
      setMsg("Enter your name");
    } else if (!username) {
      setIsErr(true);
      setMsg("Enter username");
    } else if (!password) {
      setIsErr(true);
      setMsg("Enter password");
    } else if (!secretkey) {
      setIsErr(true);
      setMsg("Enter secretkey");
    } else {
      const tcm_admin = Cookies.get("tcm_admin");
      const res = await axios.post(
        `${url}/admin/signup`,
        {
          name,
          username,
          password,
          secretkey,
        },
        {
          headers: {
            token: tcm_admin,
          },
        }
      );
      //
      if (res.data.success === true) {
        setIsErr(false);
        setMsg("New admin account created");
      } else {
        setIsErr(true);
        setMsg(res.data.message);
      }
    }
  };
  useMemo(() => {
    if (msg !== "") {
      setTimeout(() => {
        setMsg("");
      }, 2000);
    }
  }, [msg]);
  return (
    <>
      <div className="d-flex align-items-center justify-content-center px-2">
        <Card
          style={{ maxWidth: "400px", width: "400px", margin: "10px auto" }}
        >
          <div className="p-2">
            <div className="auth-title-box rounded">
              <h2>TCM Portal</h2>
            </div>
          </div>
          <CardContent>
            <h2>Add account</h2>
            <form>
              <TextField
                label="Name"
                type="text"
                variant="standard"
                fullWidth
                margin="normal"
                className="mt-0"
                autoComplete="off"
                name="name"
                value={inputs.name}
                onChange={handleInputs}
              />
              <TextField
                label="Username"
                type="text"
                variant="standard"
                fullWidth
                margin="normal"
                autoComplete="off"
                name="username"
                value={inputs.username}
                onChange={handleInputs}
              />
              <div className="password-div">
                <TextField
                  label="Password"
                  type={passwordShow === true ? "text" : "password"}
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mb-0"
                  autoComplete="off"
                  name="password"
                  value={inputs.password}
                  onChange={handleInputs}
                />
                <div className="password-show" onClick={handlePasswordShow}>
                  {passwordShow === true ? (
                    <VisibilityOffIcon color="primary" />
                  ) : (
                    <VisibilityIcon color="primary" />
                  )}
                </div>
              </div>
              <div className="password-div">
                <TextField
                  label="Secret key"
                  type={secretKeyShow === true ? "text" : "password"}
                  variant="standard"
                  fullWidth
                  margin="normal"
                  className="mb-0"
                  autoComplete="off"
                  name="secretkey"
                  value={inputs.secretkey}
                  onChange={handleInputs}
                />
                <div className="password-show" onClick={handleSecretKeyShow}>
                  {secretKeyShow === true ? (
                    <VisibilityOffIcon color="primary" />
                  ) : (
                    <VisibilityIcon color="primary" />
                  )}
                </div>
              </div>
              <div className="text-end mt-4">
                <p className={isErr ? "text-danger" : "text-success"}>{msg}</p>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSignup}
                >
                  Add account
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default AddAccount;
