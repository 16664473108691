import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { ListItem, List, Button, Drawer, ListItemText } from "@mui/material";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import NoDataFound from "../components/NoDataFound";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const User = () => {
  const { getUsers, users } = useContext(AppContext);
  useEffect(() => {
    getUsers();
  }, []);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (users.length !== 0 && location.pathname.split("/").length === 2) {
      navigate(`${users[0]._id}/sharedbyadmin`);
    }
  }, [users]);
  //
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <>
      {users.length !== 0 ? (
        <div className="container-fluid p-2">
          <Button
            onClick={handleDrawerOpen}
            className="mb-2"
            endIcon={<NavigateNextIcon />}
            variant="contained"
          >
            Users
          </Button>
          <Drawer anchor="left" open={open} onClose={handleDrawerClose}>
            <List sx={{ minWidth: "200px", padding: 0 }}>
              {users.map((item, index) => {
                return (
                  <Link
                    to={`${item._id}/sharedbyadmin`}
                    onClick={handleDrawerClose}
                    className={
                      location.pathname.split("/")[2] === item._id
                        ? "primary-text"
                        : "text-black"
                    }
                    key={index}
                  >
                    <ListItem className="border-bottom">
                      <ListItemText primary={item.name} />
                    </ListItem>
                  </Link>
                );
              })}
            </List>
          </Drawer>

          <Outlet />
        </div>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default User;
