import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, IconButton, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import { useLocation } from "react-router-dom";

const AddLead = ({ addModal, setAddModal, getAdminLeads, getAllLeads }) => {
  const { url, getAdmin } = useContext(AppContext);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState({
    customerName: "",
    customerNumber: "",
    customerSalary: "",
    creditScore: "",
    companyName: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  //
  const [creditScoreFile, setCreditScoreFile] = useState(null);
  const creditScoreFileRef = useRef(null);
  useEffect(() => {
    if (creditScoreFile !== null) {
      const maxFileSizeInMB = 10;
      const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB;
      if (creditScoreFile.type !== "application/pdf") {
        setIsError(true);
        setMessage("Only PDF format allowed");
        setCreditScoreFile(null);
        creditScoreFileRef.current.value = "";
      } else if (creditScoreFile.size > maxFileSizeInKB) {
        setIsError(true);
        setMessage(`Maximum file size is ${maxFileSizeInMB}mb`);
        setCreditScoreFile(null);
        creditScoreFileRef.current.value = "";
      }
    }
  }, [creditScoreFile]);
  //
  const location = useLocation();
  //
  const postLead = async (e) => {
    e.preventDefault();
    //
    if (!inputs.customerName) {
      setIsError(true);
      setMessage("Enter customer name");
    } else if (!inputs.customerNumber) {
      setIsError(true);
      setMessage("Enter customer mobile number");
    } else if (!inputs.customerSalary) {
      setIsError(true);
      setMessage("Enter customer salary");
    } else if (!inputs.creditScore) {
      setIsError(true);
      setMessage("Enter customer credit score");
    } else if (!inputs.companyName) {
      setIsError(true);
      setMessage("Enter company name");
    } else {
      const tcm_admin = Cookies.get("tcm_admin");
      const formdata = new FormData();
      formdata.append("customerName", inputs.customerName);
      formdata.append("customerNumber", inputs.customerNumber);
      formdata.append("customerSalary", inputs.customerSalary);
      formdata.append("creditScore", inputs.creditScore);
      formdata.append("companyName", inputs.companyName);
      if (creditScoreFile !== null) {
        formdata.append("creditScoreFile", creditScoreFile);
      }
      try {
        const res = await axios.post(`${url}/tcm/lead`, formdata, {
          headers: {
            token: tcm_admin,
          },
        });
        if (res.data.success === true) {
          location.pathname === "/" ? getAdminLeads() : getAllLeads();
          getAdmin();
          setInputs({
            customerName: "",
            customerNumber: "",
            customerSalary: "",
            creditScore: "",
            companyName: "",
          });
          setAddModal(false);
          setIsError(false);
          setMessage(res.data.message);
        } else if (res.data.success === false) {
          setIsError(true);
          setMessage(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useMemo(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <Modal
        open={addModal}
        onClose={() => setAddModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 400,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h2>Add a lead</h2>
            <IconButton aria-label="close" onClick={() => setAddModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <>
            <form>
              <TextField
                label="Customer Name"
                variant="standard"
                fullWidth
                type="text"
                margin="normal"
                name="customerName"
                value={inputs.customerName}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Customer Number"
                variant="standard"
                fullWidth
                type="number"
                margin="normal"
                name="customerNumber"
                value={inputs.customerNumber}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Customer Salary"
                variant="standard"
                fullWidth
                type="number"
                margin="normal"
                name="customerSalary"
                value={inputs.customerSalary}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Credit Score"
                variant="standard"
                fullWidth
                type="number"
                margin="normal"
                name="creditScore"
                value={inputs.creditScore}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Company name"
                variant="standard"
                fullWidth
                type="text"
                margin="normal"
                name="companyName"
                value={inputs.companyName}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Credit Score File"
                type="file"
                InputLabelProps={{ shrink: true }}
                fullWidth
                margin="normal"
                variant="standard"
                onChange={(e) => setCreditScoreFile(e.target.files[0])}
                ref={creditScoreFileRef}
                inputProps={{ accept: "application/pdf" }}
              />
              <div className="text-end">
                <p className={isError ? "text-danger" : "text-success"}>
                  {message}
                </p>
                <Button color="primary" variant="contained" onClick={postLead}>
                  Submit
                </Button>
              </div>
            </form>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default AddLead;
