import axios from "axios";
import Cookies from "js-cookie";
import React, { createContext, useEffect, useRef, useState } from "react";
const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const url = "https://api.tauruscreditmanagement.ae";
  // const url = "http://localhost:5005";
  const [admin, setAdmin] = useState(null);
  //
  const [leads, setLeads] = useState([]);
  const [leads2, setLeads2] = useState([]);
  const [adminLeads, setAdminLeads] = useState([]);
  const [adminLeads2, setAdminLeads2] = useState([]);
  const [showAll, setShowAll] = useState(false);
  //
  const getAdmin = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    const res = await axios.get(`${url}/admin/admin`, {
      headers: {
        token: tcm_admin,
      },
    });
    if (res.data.success === true) {
      setAdmin(res.data.admin);
    }
  };
  //
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.get(`${url}/user/all`, {
        headers: {
          token: tcm_admin,
        },
      });
      if (res.data.success === true) {
        setUsers(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const [requestedCount, setRequestedCount] = useState(0);
  const getRequestedCount = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    try {
      const res = await axios.get(`${url}/requested/length`, {
        headers: {
          token: tcm_admin,
        },
      });
      if (res.data.success === true) {
        setRequestedCount(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const removeUnwanted = async () => {
    const tcm_admin = Cookies.get("tcm_admin");
    await axios.get(`${url}/tcm/lead/delete/unwanted`, {
      headers: {
        token: tcm_admin,
      },
    });
  };
  useEffect(() => {
    if (admin !== null) {
      getRequestedCount();
      removeUnwanted();
    }
  }, [admin]);
  return (
    <AppContext.Provider
      value={{
        url,
        admin,
        setAdmin,
        getAdmin,
        leads,
        leads2,
        setLeads,
        setLeads2,
        adminLeads,
        adminLeads2,
        setAdminLeads,
        setAdminLeads2,
        showAll,
        setShowAll,
        getUsers,
        users,
        requestedCount,
        getRequestedCount,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
